<template>
  <div>
    <div class="base-medium-text mb-16">
      {{ $lang('loginResources', 'forgotPasswordModalHeading') }}
    </div>
    <div v-if="!requestSent">
      <div class="border-grey600 bg-grey200 rounded">
        <div class="form-row rounded border">
          <div class="flex-1">
            <TextInput :id="'checkoutSignInEmail'"
                       :placeholder="$lang('checkoutResources', 'formEmail')"
                       :type="'email'"
                       :form-input="true"
                       :error="emailError"
                       @submitInput="emailInput" />
          </div>
        </div>
      </div>
      <div class="mt-24">
        <Button :is-block="true"
                :text="$lang('loginResources', 'forgotPasswordButton')"
                :size="Sizes.lg"
                :theme="Themes.dark"
                :loading="forgotPasswordLoading"
                @click="onForgotPassword" />
      </div>
    </div>
    <div v-else class="small-base-text">
      {{ $lang('loginResources', 'forgotPasswordSuccessful') }}
    </div>
    <div
      class="mt-24 mb-8 base-medium-text text-center underline cursor-pointer select-none"
      @click="$emit('switch-form')"
    >
      {{ $lang('checkoutResources', 'signInButton') }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { Sizes, Themes } from '../constants/button-layouts';
import Button from './globals/Button.vue';
import TextInput from './form-elements/TextInput.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import * as Sentry from '@sentry/vue'

const config = useGlobalContentStore().config;
const email = ref('');
const emailError = ref('');
const forgotPasswordLoading = ref(false);
const requestSent = ref(false);

const { apiPost } = useApiFetch();

const { $lang } = useNuxtApp();

const emailInput = (input: string) => {
  email.value = input;

  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.trim()) && input) {
    emailError.value = '';
  } else if (!input) {
    emailError.value = '';
  } else {
    emailError.value = $lang(
      'validationErrorResources',
      'invalidEmailShort'
    );
  }
};

const onForgotPassword = async() => {
  try {
    if (email.value == '')
    {
      emailError.value = $lang(
        'validationErrorResources',
        'invalidEmailShort'
      );
      return;
    }

    forgotPasswordLoading.value = true;

    await apiPost(
      `user/account/passwordreset?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`,
      {
        email: email.value,
      }
    );
    requestSent.value = true;
  } catch (e) {
    Sentry.captureException(e);
  } finally {
    forgotPasswordLoading.value = false;
  }
};
</script>
<style scoped>
.form-row {
  @apply flex border-t border-l border-r;
}
</style>
